import ApiService from "@/core/services/api.service";
// import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const GET_REGIONS = "getSHIPPINGREGIONSRegions";
export const GET_COUNTRIES = "getSHIPPINGREGIONSCountries";
export const EXPORT_TABLE_DATA = "exportSHIPPINGREGIONSTableData";

// mutation types
export const SET_REGIONS = "setSHIPPINGREGIONSRegions";
export const SET_COUNTRIES = "setSHIPPINGREGIONSCountries";
export const SET_COUNTRIES_LENGTH = "setSHIPPINGREGIONSStatesLength";
export const SET_COUNTRY_LABEL = "setSHIPPINGREGIONSStatesLabel";
export const SET_EXPORT_LINK = "setSHIPPINGREGIONSExportLink";
export const SET_SELECTED_REGION_ID = "setSHIPPINGREGIONSSelectedRegionId";
export const SET_UPLOAD_TYPE = "setSHIPPINGREGIONSUploadType";

const state = {
  export_link: null,
  regions: [],
  countries: [],
  countryLabel: "Select a region",
  selectedRegionId: null,
  uploadType: null,
};

const getters = {
  getSHIPPINGREGIONSRegionsData(state) {
    return state.regions;
  },

  getSHIPPINGREGIONSCountriesData(state) {
    return state.countries;
  },

  getSHIPPINGREGIONSCountryLabel(state) {
    return state.countryLabel;
  },

  getSHIPPINGREGIONSExportUrl(state) {
    return state.export_link;
  },

  getSHIPPINGREGIONSSelectedRegionId(state) {
    return state.selectedRegionId;
  },
  getSHIPPINGREGIONSUploadType(state) {
    return state.uploadType;
  },
};

const actions = {
  [GET_REGIONS](context) {
    return new Promise((resolve) => {
      ApiService.post("/shipping/regions")
        .then(({ data }) => {
          context.commit(SET_REGIONS, data.values);
          context.commit(SET_UPLOAD_TYPE, data.type);
          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
  [GET_COUNTRIES](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/shipping/regions", payload)
        .then(({ data }) => {
          context.commit(SET_COUNTRIES, data.values);
          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_UPLOAD_TYPE](state, uploadType) {
    state.uploadType = uploadType;
  },
  [SET_REGIONS](state, regions) {
    state.regions = regions;
  },
  [SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
  [SET_SELECTED_REGION_ID](state, id) {
    state.selectedRegionId = id;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
